.main_product_container{

    margin-bottom: 10%;
    margin-left: 5%;
}

.main_product{
    width: 100%;
    height: 100%;
}

.main_product_img_div{
    /*background-color: #D9D9D9;*/
    width: 100%;
    height: 20%;
}

.main_product_title{
    font-size : 15px;
    margin-top : 2%;
  }

  .main_product_des{
    font-size : 19px;
    font-weight: 800;
    width:100%;
    margin-top : 1%;
    margin-bottom : 1%;
  }
  .main_product_mon{
    font-size : 15px;
  }
  .main_product_link_div{
    text-align: center;
  }
  .main_product_link{
    position:inherit;
    font-size : 1.2rem;
    font-weight: 500;
    text-decoration: none;
    background : #0B5ED9;
    color : white;
    border-radius : 10px;
    padding :5px;
  }