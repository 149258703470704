/* Parking.css */
.image-container {
    width: 100%;
    overflow: hidden; /* Ensure the image doesn't overflow its container */
}

.img {
    width: 100%;
    height: auto;/* Make the image fill the entire container horizontally */
    display: block;
    position: relative;
    /* Remove any default inline spacing */
}

.text{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate( -50%, -50% );
    color: white;
    text-align: center;
}
