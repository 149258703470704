.SignupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
}

.Authorization{
    width: auto;
    /*margin: 10px;*/
    text-align: left;
}

.SignupForm {
    max-width:400px;
    margin: 10px;
    text-align: left;
}