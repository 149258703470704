@font-face {
  font-family: "Pretendard";
  font-display: fallback;

  src:
          url("./font/Pretendard-Regular.otf") format("opentype")
}

body {
  margin: 0;
  padding: 0;
  font-family: "Pretendard";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

