
.common-table {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    border-spacing: 0;
}

.common-table-header-column {
    border-bottom: 1px solid #e8e8e8;
    padding: 0;
    font-size: 16px;
    padding: 10px 5px;
    font-weight: bold;
    max-width: 130px;
    min-width: 60px;
}

.common-table-row:hover {
    background-color: transparent !important;
    cursor: default;
}

.common-table-column {
    padding: 10px 5px;
}