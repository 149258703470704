  .banner {
    padding: 20px;
    background-color: #ecf0f1;
  }
  
  /*.body {*/
  /*  padding: 30px;*/
  /*}*/
  
  .card-set {
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  .card {
    flex: 0 0 auto;
    width: 150px;
    height: 150px;
    margin-right: 10px;
    background-color: #ecf0f1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
  }
  
  .banner{
    margin-bottom: 3%;
  }
  