.course_container{
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.product{
  width: 100%;
}
.product_img{
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.product_title{
    font-size : 1rem;
    font-weight: 500;
    margin-top : 8px;
    margin-bottom: 4px;
  }
  .product_des{
    font-size : 13px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .product_mon{
    font-size : 13px;
    font-weight: 500;
  }
  .product_link_div{
    text-align: center;
  }
  .product_link{
    position:inherit;
    font-size : 1.2rem;
    font-weight: 500;
    text-decoration: none;
    background : #0B5ED9;
    color : white;
    border-radius : 10px;
    padding :5px;
  }