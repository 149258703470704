.Coach_SignupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
}


.Coach_Authorization{
    width: auto;
    /*margin: 10px;*/
    text-align: left;
}

.Coach_SignupForm {
    width: auto;
    margin: 10px;
    text-align: left;
}