.Write{
    margin-left: 5%;
    margin-right: 5%;
}

.Input{
    margin-bottom: 2%;
}

/*.Button{*/
/*    position: absolute;*/
/*    align-items: center;*/
/*    !*top: 90%;*!*/
/*    !*left: 45%;*!*/
/*}*/