.Login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto; /* Set the height to 100% of the viewport height for vertical centering */
}

.LoginButton{
    margin-Bottom:2%;
    margin-top:5%;
    width:300px;
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    color:white;
}

.Singup{
    margin-top: 2%;
}

.Notsign{
    font-weight: 500;
    width: 300px;
    font-size: 16px;
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 5%;
}

.Gosign{
    font-weight: 500;
    width: 300px;
    font-size: 16px;
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
}