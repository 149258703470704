 .program {
    display: flex;
    margin-top: 56px;
    width: 100%;
  }
  
  .card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 20px;

  }
  
  .card {
    display: flex;
    flex-direction: column; /* 수직으로 정렬하기 위해 컬럼 방향으로 설정 */
    border: none;
    border-radius: 8px;
    overflow: hidden;
    /* background-color: white; */
  }
  
  .card-image {
    width: 200px;
    height: 200px; /* 이미지 비율 유지 */
    object-fit: cover;
    color: #b0e4dd;
    background-color: lightblue;
  }
  
  .card-text {
    padding: 10px;
  }
  
  .card-author,
  .card-date {
    margin: 0;
    font-size: 14px;
  }

  .navbar{
    text-align: center;
    position: fixed;
    bottom: 10%;
    left: 40%;
    display: flex;
  }

  .price, .location, .sort{
    margin-bottom: 5%;
  }
  
  /* 추가적인 스타일을 필요에 따라 수정 또는 추가할 수 있습니다. */
  
