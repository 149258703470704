.Carrer-1 {
    width:51%;
    margin-left: 25%;
    @media screen and (max-width: 1023px){
        width: 100%;
        margin-left: 0%;
    }
}
.Head {
    text-align: center;
}

.AccountInfo{
    width:80%;
    margin-left: 25%;
    @media screen and (max-width: 1023px){
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.AccountInput{
    width: 100%;
    height: 40px;

}
.next{
    font-size: 20px;
    width:20%;
    height: 40px;
    margin-left: 22%;
    margin-bottom: 3%;
    margin-top: 3%;
    @media screen and (max-width: 1023px){
        font-size: 14px;
    }
}
