/* Coach.css */

.coach-container {
    width: 100%;
    height: 400px;
    display: flex;
    margin-bottom: 5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right,#E6F6F4,#76E2D3);
}

.coach-bottom {
    width: 100%;
    height: 400px;
    display: flex;
    margin-top: 5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to top,#CAE9D6,#2B8A7C);
}

.coach-about{

    @media screen and (max-width: 1023px){
        margin-left: 40px;
        margin-right: 70px;
    }
}

.coach-about-title{
    text-align: center;
}

.coach-about-content , .coach-about-content2 {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
    @media screen and (max-width: 768px){
        margin-top: 10px;
        flex-direction: column;
        justify-content:initial;
    }
}

.coach-about-content1{
    margin-right: 10px;
    height: 175px;
    width: 30%;
    padding: 10px 20px 20px 20px;
    border-radius: 8px;
    @media screen and (max-width: 768px){
        width: 100%;
        margin-bottom: 10px;
        padding: 4px 16px;
    }
}

.coach-about-content3{
    height: 175px;
    width: 30%;
    padding: 10px 20px 20px 20px;
    border-radius: 8px;
    @media screen and (max-width: 768px){
        width: 100%;
        padding: 4px 16px;
    }
}


/* Add additional styling for your elements if needed */
